
import store from '../store'
import Fuse from "fuse.js";
import icons from "./icons";
import axios from "axios";
import lodash from "lodash";

const helpFunctions = {
    modal_functions: {
        openModal(ref) {
            this.$refs[ref].open();
        },
        closeModal(ref) {
            this.$refs[ref].close();
        },
    },
    fuse_options: {
        isCaseSensitive: false,
        includeScore: true,
        shouldSort: true,
        minMatchCharLength: 3,
    },
    pages: {
        certificates: {
            title: store.getters.translate("certificates"),
            name: "certificates",
            model: "certificate",
        },
        companies: {
            title: store.getters.translate("companies"),
            name: "companies",
            model: "company"
        },
        contacts: {
            title: store.getters.translate("contacts"),
            name: "contacts",
            model: "contact",
        },
        dashboard: {
            title: store.getters.translate("dashboard"),
            name: "dashboard",
        },
        employees: {
            title: store.getters.translate("employees"),
            name: "employees",
            model: "employee",
        },
        fields: {
            title: store.getters.translate("field"),
            name: "fields",
            model: "field",
        },
        notes: {
            title: store.getters.translate("note"),
            name: "notes",
            model: "note"
        },
        people: {
            title: store.getters.translate("people"),
            name: "people",
        },
        projects: {
            title: store.getters.translate("projects"),
            name: "projects",
            model: "project"
        },
        profile: {
            title: store.getters.translate("profile"),
            name: "profile",
        },
        roles: {
            title: store.getters.translate("roles"),
            name: "roles",
            model:'role'
        },
        settings: {
            title: store.getters.translate("settings"),
            name: "settings",
        },
        tags: {
            title: store.getters.translate("tag"),
            name: "tags",
            model: "tag"
        },
        tasks: {
            title: store.getters.translate("task"),
            name: "tasks",
            model: "task"
        },
        templates: {
            title: store.getters.translate("templates"),
            name: "templates",
            model: "template"
        },
        tokens: {
            title: store.getters.translate("tokens"),
            name: "tokens",
            model: "token"
        },
        translations: {
            title: store.getters.translate("translations"),
            name: "translations",
            model: "translation",
        },
        workers: {
            title: store.getters.translate("workers"),
            name: "workers",
            model: "worker",
        },
    },
    models: {
        addresses: 'Address',
        certificates: 'Certificate',
        companies: 'Company',
        contacts: 'Contact',
        emailaddresses: 'Emailaddress',
        employees: 'Employee',
        fields: 'Field',
        notes: 'Note',
        people: 'Person',
        projects: 'Project',
        roles: 'Role',
        tags: 'Tag',
        tasks: 'Task',
        templates: 'Template',
        tokens: 'Token',
        translations: 'Translation',
        users: 'User',
        workflows: 'Workflow',
        workers: 'Worker',
    },
    models_for_fields_table: [
        { value: "Certificate", text: store.getters.translate("certificates") },
        { value: "Company", text: store.getters.translate("companies") },
        { value: "Contact", text: store.getters.translate("contacts") },
        { value: "Employee", text: store.getters.translate("employees") },
        { value: "Note", text: store.getters.translate("notes") },
        { value: "Project", text: store.getters.translate("projects") },
        { value: "Role", text: store.getters.translate("roles") },
        { value: "Tag", text: store.getters.translate("tags") },
        { value: "Task", text: store.getters.translate("tasks") },
        { value: "Template", text: store.getters.translate("templates") },
        { value: "Translation", text: store.getters.translate("translations") },
        { value: "User", text: store.getters.translate("users") },
        { value: "Workflow", text: store.getters.translate("workflows") },
        { value: "Worker", text: store.getters.translate("workers") },
    ],
    field_types: [
        { value: "text_field", text: store.getters.translate("text_field") },
        { value: "text_area", text: store.getters.translate("text_area") },
        { value: "boolean", text: store.getters.translate("boolean") },
        { value: "select_single", text: store.getters.translate("select_single") },
        { value: "select_multiple", text: store.getters.translate("select_multiple") },
        { value: "date", text: store.getters.translate("date") },
        { value: "read_only", text: store.getters.translate("read_only") },
        { value: "link", text: store.getters.translate("link") },
    ],
    models_with_links: [
        'certificate',
        'company',
        'contact',
        'employee',
        'note',
        'project',
        'tag',
        'template',
        'workflow',
        'worker',
    ],
    can_restore: [
        'certificate',
        'company',
        'contact',
        'employee',
        'note',
        'project',
        'tag',
        'task',
        'template',
        'workflow',
        'worker',
    ],
    base_table_options: {
        certificates: {
            no_import: true,
        },
        companies: {
            no_import: true,
            avatar: true,
        },
        contacts: {
            no_import: true,
            avatar: true,
        },
        employees: {
            no_import: true,
            avatar: true,
        },
        fields: {
            no_import: true,
            no_tags: true,
            no_select: true,
        },
        notes: {
            no_import: true,
            copy: true,
            no_create: true
        },
        projects: {
            no_import: true,
        },
        roles: {
            no_import: true,
            no_tags: true,
            no_select: true
        },
        tags: {
            no_import: true,
            no_tags: true,
        },
        tasks: {
            no_import: true,
        },
        templates: {
            no_import: true,
            copy: true,
        },
        translations: {
            no_import: true,
            no_tags: true,
        },
        workflows: {
            no_import: true,
            no_tags: true,
        },
        workers: {
            no_import: true,
            avatar: true,
        },
    },
    base_table_includable_options: {
        companies: {
            contacts: {
                create: true,
                link: true,
                detachable: true,
            },
            workers: {
                create: true,
                link: true,
                detachable: true,
            },
            notes: {
                create: true,
                link: false,
                detachable: false,
            }
        },
        contacts: {
            companies: {
                create: false,
                link: true,
                detachable: true,
            },
            workers: {
                create: false,
                link: true,
                detachable: true,
            },
            notes: {
                create: true,
                link: false,
                detachable: false,
            }
        },
        employees: {
            roles: {
                create: false,
                link: true,
                detachable: true,
            },
            tokens: {
                create: true,
                link: false,
                detachable: false,
            },
            notes: {
                create: true,
                link: false,
                detachable: false,
            }
        },
        projects: {
            tasks: {
                create: true,
                link: true,
                detachable: true,
            },
        },
        workers: {
            certificates: {
                create: true,
                link: true,
                detachable: true,
            },
            companies: {
                create: false,
                link: true,
                detachable: true,
            },
            contacts: {
                create: true,
                link: true,
                detachable: true,
            },
            notes: {
                create: true,
                link: false,
                detachable: false,
            }
        },
    },
    related_models: [
        {
            id: 'Worker',
            name: store.getters.translate('worker'),
            notifable: true,
            children: [{
                id: 'certificates',
                name: store.getters.translate('certificates'),
                plural: true,
                notifable: false,
            }]
        },
        {
            id: 'Note',
            name: store.getters.translate('note'),
            notifable: false,
            children: [{
                id: 'workers',
                name: store.getters.translate('workers'),
                notifable: true
            }],
        },
        {
            id: 'Project',
            name: store.getters.translate('project'),
            notifable: false,
            children: [{
                id: 'tasks',
                name: store.getters.translate('tasks'),
                notifable: false
            }],
        }
    ],
    default_trigger: {
        workflow_id: null,
        model: "",
        type: "tag_exists",
        from: "",
        to: "",
        operator: "=",
        parameters: {
            operator: "=",
            compared_value: "",
            compared_type: "",
        },
        amount_of_repeats: 1,
    },
    default_action: {
        workflow_id: null,
        type: "notify_users",
        models: [],
        message: "",
        parameters: {
            is_request: false,
            request_fulfilled: false,
            generate_document: false,
            new_note: false,
            template_id: null,
            document_name: null,
            attach_to: [],
            linked_model_attach_to: [],
            tree: null,
            send_email: false,
            tags: [],
            only_latest_record: false,
            notify_contacts: false,
            document_type: null,
            repeat: false
        },
    },
    trigger_types: [
        {
            value: "tag_exist",
            text: store.getters.translate("tag_exist"),
            icon: "mdi-tag-plus-outline",
        },
        {
            value: "field_changes",
            text: store.getters.translate("field_changes"),
            icon: "mdi-form-textbox",
        },
        {
            value: "scheduler",
            text: store.getters.translate("scheduler"),
            icon: "mdi-calendar",
        },
        {
            value: "before_date",
            text: store.getters.translate("before_date"),
            icon: "mdi-calendar",
        },
        {
            value: "after_date",
            text: store.getters.translate("after_date"),
            icon: "mdi-calendar",
        },
    ],
    action_types: [
        {
            value: "notify_current_user",
            text: store.getters.translate("notify_current_user"),
            icon: "mdi-message-processing-outline",
        },
        {
            value: "notify_users",
            text: store.getters.translate("notify_users"),
            icon: "mdi-android-messages",
        },
        {
            value: "notify_roles",
            text: store.getters.translate("notify_roles"),
        },
        {
            value: "notify_model",
            text: store.getters.translate("notify_model"),
            icon: "mdi-account-arrow-right",
        },
        {
            value: "attach_tag",
            text: store.getters.translate("attach_tag"),
            icon: "mdi-tag-plus-outline",
        },
        {
            value: "remove_tag",
            text: store.getters.translate("remove_tag"),
            icon: "mdi-tag-remove-outline",
        },
        {
            value: "create_note",
            text: store.getters.translate("create_note"),
            icon: "mdi-note-plus",
        },
    ],
    register_worker_model: {
        worker: {
            notify: false,
            reference: "",
            custom_fields: {},
            person: {
                type: "worker",
                initials: null,
                first_name: null,
                insertion: null,
                last_name: null,
                date_of_birth: null,
                phonenumber: null,
                mobilenumber: null,
                billingnumber: null,
                gender: null,
                place_of_birth: null,
                addresses: [],
                access_to_portal: true,
                country_of_birth_id: null,
                nationality_id: null,
                financial_name: null
            },
        },
        company: {
            name: null,
            reference: null,
            phonenumber: null,
            billingnumber: null,
            addresses: [],
        },
        contact: {
            notify: false,
            person: {
                type: "contact",
                initials: null,
                first_name: null,
                insertion: null,
                last_name: null,
                date_of_birth: null,
                phonenumber: null,
                billingnumber: null,
                gender: null,
                country_of_birth_id: null,
                place_of_birth: null,
                nationality_id: null,
                addresses: [],
            },
            no_contact: false,
        },
    },
    //BASE TABLE AND BASE LINK FUNCTIONS
    sortByTagsBase(sorted_array, tags) {
        var response = [];
        for (let i = 0; i < sorted_array.length; i++) {
            if (sorted_array[i].tags.length > 0) {
                var found = false;
                for (let x = 0; x < sorted_array[i].tags.length; x++) {
                    for (let y = 0; y < tags.length; y++) {
                        if (sorted_array[i].tags[x] == tags[y]) {
                            response.push(sorted_array[i]);
                            found = true;
                            break;
                        }
                    }
                    if (found) {
                        break;
                    }
                }
            }
        }
        return response;
    },
    sortByColumn(sorted_array, sortBy, sortDesc) {
        let sort_field = sortBy;
        if (sortDesc) {
            sorted_array.sort((a, b) => {
                if (a[sort_field] == null) {
                    return 1;
                } else if (b[sort_field] == null) {
                    return -1;
                } else {
                    if(sort_field === 'search_score') {
                        return parseInt(a[sort_field].replace("%")) < parseInt(b[sort_field].replace("%")) ? 1 : parseInt(b[sort_field].replace("%")) < parseInt(a[sort_field].replace("%")) ? -1 : 0;
                    }
                    else {
                        return a[sort_field] < b[sort_field] ? 1 : b[sort_field] < a[sort_field] ? -1 : 0;
                    }
                }
            });
        } else {
            sorted_array.sort((a, b) => {
                if (a[sort_field] == null) {
                    return -1;
                } else if (b[sort_field] == null) {
                    return 1;
                } else {
                    if(sort_field === 'search_score') {
                        return parseInt(a[sort_field].replace("%")) > parseInt(b[sort_field].replace("%")) ? 1 : parseInt(b[sort_field].replace("%")) > parseInt(a[sort_field].replace("%")) ? -1 : 0;
                    }
                    else {
                        return a[sort_field] > b[sort_field] ? 1 : b[sort_field] > a[sort_field] ? -1 : 0;
                    }
                }
            });
        }
        return sorted_array;
    },
    searchInSortedArray(sorted_array, response_fields, search, search_column) {
        if(search_column && search_column !== 'all') {
            this.fuse_options.keys = [search_column];
        }
        else {
            this.fuse_options.keys = Object.keys(response_fields);
        }
        if (this.fuse_options.keys.includes("updated_at")) {
            this.fuse_options.keys.splice(this.fuse_options.keys.indexOf("updated_at"), 1);
        }
        if (this.fuse_options.keys.includes("created_at")) {
            this.fuse_options.keys.splice(this.fuse_options.keys.indexOf("created_at"), 1);
        }
        if (this.fuse_options.keys.includes("tags")) {
            this.fuse_options.keys.splice(this.fuse_options.keys.indexOf("tags"), 1);
        }
        if (this.fuse_options.keys.includes("search_score")) {
            this.fuse_options.keys.splice(this.fuse_options.keys.indexOf("search_score"), 1);
        }
        const fuse = new Fuse(sorted_array, this.fuse_options);
        let result = fuse.search(search);
        var response = [];
        for (let x = 0; x < result.length; x++) {
            result[x].item.search_score =
                100 - Math.floor(result[x].score * 100) + "%";
            response.push(result[x].item);
        }
        return response;
    },
    //UNIVERSAL FUNCTIONS
    async getFields(model, action) {
        let additionalModels = {};
        const response = await axios.get(store.getters.appUrl + "v2/fields?source=site&" + action + "=true&model_type=" + model);
        let data = response.data.data;
        let tags_required = false;
        let usual_fields = [];
        let custom_fields = [];
        for (let x = 0; x < data.length; x++) {
            if (data[x].custom_field == 1) {
                if(data[x].field_type === 'select_single' || data[x].field_type === 'select_multiple') {
                    for (let i = 0; i < data[x].available_options.length; i++) {
                        data[x].available_options[i] = {
                            text: store.getters.translate(data[x].available_options[i]),
                            value: data[x].available_options[i],
                        };
                    }
                }
                custom_fields.push(data[x]);
            }
            else if(data[x].name === 'tags') {
                tags_required = true;
            }
            else {
                if(data[x].name === 'country_of_birth_id') {
                    if(!additionalModels.countries) {
                        additionalModels.countries = await this.getAdditionalModels("countries", "country_id", ["country_name"]);
                    }
                    data[x].available_options = lodash.sortBy(additionalModels.countries, 'text');
                }
                else if(data[x].name === 'nationality_id') {
                    if(!additionalModels.nationalities) {
                        additionalModels.nationalities = await this.getAdditionalModels("nationalities", "nationality_id", ["nationality_name"]);
                    }
                    data[x].available_options = lodash.sortBy(additionalModels.nationalities, 'text');
                }
                else if(data[x].name === 'project_id') {
                    if(!additionalModels.projects) {
                        additionalModels.projects = await this.getAdditionalModels("projects", "id", ["name"]);
                    }
                    data[x].available_options = lodash.sortBy(additionalModels.projects, 'text');
                }
                else if(data[x].name === 'worker_id') {
                    if(!additionalModels.workers) {
                        additionalModels.workers = await this.getAdditionalModels("workers", "id", ["name"]);
                    }
                    data[x].available_options = lodash.sortBy(additionalModels.workers, 'text');
                }
                else if(data[x].field_type === 'select_single' || data[x].field_type === 'select_multiple') {
                    for (let i = 0; i < data[x].available_options.length; i++) {
                        data[x].available_options[i] = {
                            value: data[x].available_options[i],
                            text: store.getters.translate(data[x].available_options[i]),
                        };
                    }
                }
                usual_fields.push(data[x]);
            }
        }
        return { tags_required: tags_required, usual_fields: usual_fields, custom_fields: custom_fields };
    },
    async getAdditionalModels(model, option_id, option_name) {
        let models = [];
        const response = await axios.get(store.getters.appUrl + "v2/" + model);
        if(response.data.data) {
            response.data.data.forEach((option) => {
                let text = "";
                for (let i = 0; i < option_name.length; i++) {
                    text += store.getters.translate(option[option_name[i]]) + " | ";
                }
                models.push({
                    value: option[option_id],
                    text: text.substring(0, text.length-3)
                });
            });
        }
        else {
            response.data.forEach((option) => {
                let text = "";
                for (let i = 0; i < option_name.length; i++) {
                    text += store.getters.translate(option[option_name[i]]) + " | ";
                }
                models.push({
                    value: option[option_id],
                    text: text.substring(0, text.length-3)
                });
            });
        }
        return models;
    },
    modelFieldsFilled(toasted, record, fields, ignore_fields = []) {
        let status = true;
        for (let i = 0; i < fields.length; i++) {
            if ((fields[i].name === 'first_name' || fields[i].name === 'last_name') && (!record[fields[i].name] || record[fields[i].name] === '')) {
                toasted.error(store.getters.translate("required_field") + ": " + store.getters.translate('name'));
                status = false;
            }
            else if (fields[i].mandatory && !ignore_fields.includes(fields[i].name) && fields[i].field_type !== 'boolean' && (!record[fields[i].name] || record[fields[i].name] === '')) {
                toasted.error(store.getters.translate("required_field") + ": " + store.getters.translate(fields[i].name));
                status = false;
            }
        }
        return status;
    },
    replaceIDwithNameBaseTable(fields) {
        if(fields.project_id) {
            fields.project_name = "select_single";
            delete fields.project_id;
        }
        if(fields.worker_id) {
            fields.worker_name = "select_single";
            delete fields.worker_id;
        }
        return fields;
    },
    replaceIDwithNameBaseTableInc(name) {
        if(name === "project_id") {
            return "project_name";
        }
        else if(name === "worker_id") {
            return "worker_name";
        }
        else {
            return name;
        }
    },
    replaceLastChar(model) {
        if(model === 'company') {
            return 'companies';
        }
        else {
            return model + "s";
        }
    },
    async loadModel(toasted, model, model_id) {
        const response = await axios.get(store.getters.appUrl + "v2/" + model + "/" + model_id).catch((error) => { toasted.error(error.message); });
        if (response && response.status === 200) {
            let result = response.data;
            let modelsWithCustomFields = ["companies", "contacts", "employees", "workers"];
            if(modelsWithCustomFields.includes(model)) {
                if (result.custom_fields == null || Array.isArray(result.custom_fields) || typeof result.custom_fields != "object") {
                    result.custom_fields = {};
                }
            }
            return result;
        } else {
            toasted.error(response.message);
            return false;
        }
    },
    async createModel(toasted, model, record) {
        const response = await axios.post(store.getters.appUrl + "v2/" + model, record).catch((error) => { toasted.error(error.message); });
        if (response && response.status === 200) {
            toasted.success(store.getters.translate("success"));
            return response.data.id;
        } else {
            toasted.error(response.message);
            return false;
        }
    },
    async saveModel(toasted, model, record) {
        const response = await axios.patch(store.getters.appUrl + "v2/" + model + "/" + record.id, record).catch((error) => { toasted.error(error.message); });
        if (response && response.status === 200) {
            toasted.success(store.getters.translate("success"));
            return true;
        } else {
            toasted.error(response.message);
            return false;
        }
    },
    async deleteModel(toasted, model, model_id) {
        const response = await axios.delete(store.getters.appUrl + "v2/" + model + "/" + model_id).catch((error) => { toasted.error(error.message); });
        if (response && response.status === 200) {
            toasted.success(store.getters.translate("successfully_deleted"));
            return true;
        } else {
            toasted.error(response.message);
            return false;
        }
    },
    async saveFile(toasted, model, formData) {
        const response = await axios.post(store.getters.appUrl + "v2/" + model + "/uploadfiles", formData, {
            headers: { "Content-Type": "multipart/form-data" }
        }).catch((error) => {
            toasted.error(error);
            return false;
        });

        if (response && response.status === 200) {
            toasted.success(store.getters.translate("success"));
            return response;
        }
    },
    async deleteFile(toasted, model, payload) {
        const response = await axios.post(store.getters.appUrl + "v2/" + model + "/deletefiles", payload)
            .catch((error) => {
                toasted.error(error);
                return false;
            })
        if (response && response.status === 200) {
            toasted.success(store.getters.translate("success"));
            return response;
        }
    },
    getLocalStorageValue(value_name) {
        if (localStorage.getItem(value_name)) {
            return JSON.parse(localStorage.getItem(value_name));
        }
        return null;
    },
    setLocalStorageValue(value_name, value) {
        localStorage.setItem(value_name, JSON.stringify(value));
    },
    getBreadcrumbs(module_name, current = null) {
        var response = [{
            text: "Dashboard",
            disabled: false
        }];
        if(module_name !== "dashboard") {
            response[0].to = "/";
            response[1] = {
                text: store.getters.translate(module_name)
            }
            if(current == null) {
                response[1].disabled = true;
            }
            else {
                response[1].disabled = false;
                response[1].href = "/" + module_name;
                response[2] = {
                    text: current,
                    disabled: true
                }
            }
        }
        return response;
    },
    //PORTAL FUNCTIONS
    getMenuItems(type) {
        var menu_items = [
            {
                title: store.getters.translate("home"),
                icon: "mdi-home",
                tab: 0,
            },
            {
                title: store.getters.translate("your_contactinformation"),
                icon: "mdi-account",
                tab: 1,
            },
        ];
        if (type === "contact") {
            menu_items.push({
                title: store.getters.translate("workers"),
                icon: icons["workers"],
                tab: 2,
            });
        }
        else if (type === "worker") {
            menu_items.push({
                title: store.getters.translate("documents"),
                icon: icons["notes"],
                tab: 2,
            });
        }
        return menu_items;
    },
}

export default helpFunctions