export default {
    // modules
    workers: 'mdi-account-box',
    certificates: 'mdi-certificate',
    projects: 'mdi-account-hard-hat',
    tasks: 'mdi-format-list-bulleted',
    notes: 'mdi-note',
    translations: 'mdi-translate',
    templates: 'mdi-file-document-edit',
    companies: 'mdi-office-building',
    people: 'mdi-human-male-female',
    tags: 'mdi-tag',
    // actions
    created: 'mdi-plus',
    fields: 'mdi-format-letter-matches',
    users: 'mdi-account',
    roles: 'mdi-account-group',
    workflows: 'mdi-transit-connection-variant',
    settings: 'mdi-cogs',
    profile: 'mdi-face',
    contacts: 'mdi-contacts-outline',
    tokens: 'mdi-account-key',
    employees: 'mdi-account',
    files: 'mdi-file-multiple',
}